
export default {
  props: {
    id: {
      default: '',
      type: String,
    },
    header: {
      default: '',
      type: String,
    },
    intro: {
      default: '',
      type: String,
    },
    bg: {
      default: 'white',
      type: String,
    },
    hsize: {
      default: '2',
      type: String,
    },
    clear: {
      default: '',
      type: String,
    },
    removepadding: {
      default: '',
      type: String,
    },
    addflex: {
      default: '',
      type: String,
    }
  },
  render() {
    const slot = this.$slots.default
    //  return slot.text.replace(/,/g, "")
    return slot
  },

}
